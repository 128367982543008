<template>
  <div style="  background-color: #f2f7fb; ">

    <div class="container">
      <div class="dnewsListBox" ref="dnewsListBox">
              <a class="items"
                 v-for="(obj, index) in newslist"
                 :key="index"

                 :href="'#/infolistItem?id='+obj.noticeId"
              >
                          <div class="img">

                            <img :src="obj.coverPath" />
                          </div>
                          <div class="info">
                            <h4 class="tit">{{obj.noticeTitle}}</h4>
                            <div class="time">
                              <span>{{ obj.createTime }}</span>
                                <div class="go">>  </div>
                            </div>
                          </div>

              </a>


      </div>
    </div>


<!--    <el-row type="flex" justify="center">-->
<!--      &lt;!&ndash; <el-col  :lg="3" :md="2" :sm="1" :xs="0.5"></el-col> &ndash;&gt;-->
<!--      <el-col  :lg="13" :md="15" :sm="17" :xs="23">-->
<!--        <p class="title">新闻动态</p>-->
<!--        <p v-if="screenWidth < 768">-->
<!--          <el-image-->
<!--              style="width: 100%; height: 100px"-->
<!--              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"-->
<!--              :fit="'cover'"-->
<!--            ></el-image>-->
<!--        </p>-->
<!--        -->
<!--        <el-row-->
<!--          type="flex"-->
<!--          justify="space-between"-->
<!--          align="middle"-->
<!--          v-for="(obj, index) in newslist"-->
<!--          :key="index"-->

<!--        >-->
<!--          <el-col :span="8" v-if="index == 0&&screenWidth > 768">-->
<!--            <el-image-->
<!--              style="width: 200px; height: 100px"-->
<!--              :src="require(obj.coverPath)"-->
<!--              :fit="'cover'"-->
<!--            ></el-image>-->
<!--          </el-col>-->

<!--          <el-col class="news-container" :span="12">-->
<!--            <h5>{{obj.noticeTitle }} ({{ obj.expiresDate | formdate }})</h5>-->

<!--            <p v-html="obj.noticeContent">-->

<!--            </p>-->
<!--          </el-col>-->
<!--          <el-col :span="4" style="text-align: center; font-size: 12px">-->
<!--            {{ obj.expiresDate | formdate }}-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row style="border:none;">-->
<!--          <el-pagination-->
<!--            background-->
<!--            prev-text="上一页"-->
<!--            next-text="下一页"-->
<!--            :pager-count="5"-->
<!--            :total="1"-->
<!--            layout="prev, pager, next"-->
<!--            >-->
<!--          </el-pagination>-->
<!--        </el-row>-->
<!--      </el-col>-->
<!--      &lt;!&ndash; <el-col  :lg="1" :md="1" :sm="1" :xs="0"></el-col> &ndash;&gt;-->
<!--      <el-col  :lg="4" :md="4" :sm="4" :xs="0" :offset="1" class="sidemenu">-->
<!--        <p class="title">新闻类别</p>-->
<!--        <ul style="min-width: 400px">-->
<!--          <li-->
<!--            v-for="(item, index) in dynamiclist"-->
<!--            :key="index"-->
<!--            :class="{dynamicContainer:true,current:active[index]}"-->
<!--          >-->
<!--            <a :href="item.url" :class="{  }">{{ item.title }}</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </el-col>-->
<!--      &lt;!&ndash; <el-col  :lg="3" :md="2" :sm="1" :xs="0.5"></el-col> &ndash;&gt;-->
<!--    </el-row>-->
  </div>
</template>

<script>
import {getNoticeList} from "@/api/kms/notice";
// import { Loading } from 'element-ui';
export default {
  data() {
    return {
      newslist: [
        // {
        //
        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   company: "",
        //   description:
        //     "",
        //   date: new Date(),
        // },
        // {
        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   company: "",
        //   description:
        //     "",
        //   date: new Date(),
        // },
        // {
        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   company: "",
        //   description:
        //     "",
        //   date: new Date(),
        // },
        // {
        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   company: "",
        //   description:
        //     "",
        //   date: new Date(),
        // },
      ],
      // dynamiclist: [
      //   {
      //     title: "全部动态",
      //     url: "#",
      //   },
      //   {
      //     title: "签约新闻",
      //     url: "#",
      //   },
      //   {
      //     title: "验收新闻",
      //     url: "#",
      //   },
      // ],
      screenWidth:null,
      //右侧菜单暂时值，后期采用路由高亮
      active:[true,false,false],
    };
  },
  methods:{

    initData(){
      // const loadingInstance = Loading.service({
      //   target: this.$refs.dnewsListBox,
      //   fullscreen:false,
      //   text:'新闻正在加载中...',
      //   spinner: 'el-icon-loading'
      // })

      getNoticeList().then(response=>{

        response.data.rows.forEach((item)=>{
          item.coverPath=process.env.VUE_APP_BASE_API+ item.coverPath
        })
        this.newslist=response.data.rows

        // loadingInstance.close()
      })
    },
    getWidth(){
      //用于获得页面中某个元素的左,上,右和下分别相对浏览器视窗的位置，及元素自身的宽度和高度
      this.screenWidth = document.body.getBoundingClientRect().width;
    }
  },

  created(){
    window.addEventListener('resize',this.getWidth)
    this.initData()




  }, mounted() {


  },
  Destroyed() {
    window.removeEventListener('resize',this.getWidth)
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 12px;
  color: #999;
  margin-bottom: 24px;
}
.el-row {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.news-container {
  transition: color 0.5s;
  h5 {
    color: #000;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 0 6px;
  }
  p {
    display: -webkit-box;
    color: #999;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 40px;
    word-break: break-all;
    line-height: 20px;
    -webkit-box-orient: vertical; //子元素应该被水平或垂直排列
    -webkit-line-clamp: 2; //3行后显示省略号
  }
}
.dynamicContainer {
  height: 66px;
  margin-bottom: 6px;
  border-bottom: 1px solid #cccccc;
  a {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    padding: 20px 0;
    padding-left: 0px;
    transition: all .3s;
  }
  a:hover{
    background:rgba($color: #999, $alpha: 0.1);
    text-indent: 2em;
  }
}
.current{
    font-weight: bold;
    border-bottom: 1px solid #333;
  }
.container {
      width: 1440px;
    }
//@media (min-width: 1680px){
//  .container {
//    width: 1440px;
//  }
//}
//
//@media (min-width: 1440px){
//  .container {
//    width: 1170px;
//  }
//}

//@media (min-width: 1680px){
//  .container {
//    width: 1170px;
//  }
//}




//@media (min-width: 1300px){
//  .container {
//    width: 750px;
//  }
//}





.container {
  padding-top: 5%;
  min-height: 100vh;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;


}
.dnewsListBox{
  margin-top: 10%;
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.dnewsListBox > .items{
   display: block;
   background: #fff;
   width: 454px;
   margin: 0 12px 24px;
   overflow: hidden;
   -webkit-transition: 0.3s;
   -o-transition: 0.3s;
   transition: 0.3s;
  text-align: center;
 }


@media (max-width: 1600px) {
  .dnewsListBox > .items{
    width: 320px;
    margin: 0 10px 20px;
  }
    .container {
      width: 1100px;
    }
  .dnewsListBox .items .img {
    width: 100%;
    height: 180px;
  }
}
@media (max-width: 1440px) {
  .dnewsListBox > .items{
    width: 310px;
    margin: 0 6px 12px;
  }
  .container {
    width: 1050px;
  }
  .dnewsListBox .items .img {
    width: 100%;
    height: 180px;
  }

}
//@media (max-width: 1440px) {
//  .dnewsListBox > .items{
//    width: 300px;
//    margin: 0 10px 20px;
//  }
//  .dnewsListBox .items .img {
//    width: 340px;
//    height: 160px;
//  }
//
//}



//@media (max-width: 1336px){
//  .dnewsListBox > .items {
//    width: 330px;
//    margin: 0 auto 12px;
//  }
//
//  .dnewsListBox .items .img {
//    width: 100%;
//    height: 155px;
//  }
//}
.img img {
  width: 100%;
  height: 100%;
  //-o-object-fit: cover;
  //object-fit: cover;
  display: block;
  image-rendering:-moz-crisp-edges;
  image-rendering:-o-crisp-edges;
  image-rendering:-webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode:nearest-neighbor;

}


.dnewsListBox .items .img{
  width: 100%;
  height: 254px;
  overflow: hidden;
}
.dnewsListBox .items .info {
  padding: 30px 40px 0;
}
.dnewsListBox .items .info .tit {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 40px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: 400;
}

.dnewsListBox .items .info .time {
  position: relative;
  border-top: 1px solid #e6e6e6;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dnewsListBox .items .info .time span {
  font-size: 16px;
  color: grey;
}
.dnewsListBox .items .info .time .go {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  //-webkit-transform: rotate(-90deg);
  //-ms-transform: rotate(-90deg);
  //transform: rotate(-90deg);
  //fill: grey;
}
.dnewsListBox .items .info .time::after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #073d96;
  -webkit-transition: all 1.6s ease 0s;
  -o-transition: all 1.6s ease 0s;
  transition: all 1.6s ease 0s;
}


.dnewsListBox .items:hover {
  /* 盒子阴影 */
  box-shadow: 4px 20px 40px 5px rgba(0, 0, 0, .1);
  /* 盒子向上移动效果，改变上外边距 */
  margin-top: -8px;
  color: #073d96;
}

.dnewsListBox .items:hover  .info .time::after{
  width: 100%;
}
.dnewsListBox .items .img img:hover {
  animation: move .5s linear forwards;
}

@keyframes move {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}




// *{border: 1px solid red;}
</style>