<template>
  <div>
    <tryout-project></tryout-project>
    <div class="foot-nav">
      <div class="content">
        <div class="top">
          <div class="one">
            <ul>
              <li><a href="#">首页</a></li>
              <li><a href="#">合作</a></li>
            </ul>
          </div>
          <div class="two">
            <p>产品优势</p>
            <ul>
              <li><a href="#">基于B/S架构</a></li>
              <li><a href="#">多全宗管理</a></li>
              <li><a href="#">分布式存储</a></li>
              <li><a href="#">自定义门类</a></li>
            </ul>
          </div>
          <div class="three">
            <p>关于禾祺科技</p>
            <ul>
              <li><a href="#">企业介绍</a></li>
              <li><a href="#">联系我们</a></li>
            </ul>
          </div>
          <div class="four">
            <p>应用</p>
            <ul>
              <li><a href="http://118.195.251.38:8888/" target="_blank">系统演示</a></li>
            </ul>
          </div>
          <div class="five">
            <p>联系我们</p>
            <ul>
              <li>咨询热线: 4008803557或15735645888</li>
              <li>公司地址: 山西省太原市新建路10号院</li>
              <li><img src="../assets/images/qr_wx.png" alt="" /></li>
              <li>扫一扫加微信</li>
            </ul>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <img src="../assets/images/logo4.png" alt="" />
          </div>
          <div class="right">
            <p>
              Copyright ©2023 山西禾祺科技有限公司 版权所有.
              <a href="https://beian.miit.gov.cn/" target="_blank">晋ICP备2023015082号-1</a>
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tryoutProject  from  "@/views/components/tryoutProject.vue"
  export default {
    components:{
      tryoutProject
    }
  }
</script>

<style>
.foot-nav {
  background-color: #414667;
}

.foot-nav .content {
  width: 70%;
  margin: 0 auto;
  padding: 40px 0 20px;
}

.foot-nav .content .top {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px solid #d1d2d866;
}

.foot-nav .content .top div {
  flex: 1;
}

.foot-nav .content .top div ul li {
  margin-bottom: 8px;
  color: #d1d2d8;
  font-size: 14px;
}

.foot-nav .content .top div p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 8px;
}

.foot-nav .content .top div ul li a {
  font-size: 12px;
  color: #d1d2d8;
}

.foot-nav .content .top .one a {
  color: #fff;
  font-size: 16px;
}

.foot-nav .content .top .four li a {
  display: flex;
}

.foot-nav .content .top .four li:last-child a::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../assets/images/windows.png");
}

.foot-nav .content .top .five li img {
  width: 100px;
  height: 100px;
  margin-top: 20px;
}

.foot-nav .content .bottom {
  padding-top: 20px;
  display: flex;
}
.foot-nav .content .bottom .left img {
  width: 120px;
  height: 38px;
  margin-right: 20px;
}
.foot-nav .content .bottom p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 5px;
}
.foot-nav .content .bottom p:last-child a:last-child::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 20px;
}
.foot-nav .content .bottom a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}
/*.foot-nav .content .bottom .right p:last-child a {
  display: flex;
}*/

@media (max-width: 1200px) {
  .foot-nav .content .top div {
  }
}
@media (max-width: 992px) {
  .foot-nav .content .top {
  }
}
@media (max-width: 768px) {
  .foot-nav .content {
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px 20px;
  }

  .foot-nav .content .top div{
	flex-basis: 50%;
  }
  .foot-nav .content .top .three,.foot-nav .content .top .four{
	margin:20px 0;
  }
}
</style>

