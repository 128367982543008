<template>
  <div style="overflow: hidden" >
    <!-- 主导航栏 -->
    <div
      class="nav"
      :style="{
        backgroundColor: navBgcolor,
        boxShadow: !DarkColor ? 'none' : 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
      }"
    >
      <div class="container">
        <div class="left">
          <a href="#/index"
             class="logo"
             :style="{ backgroundImage: `url(${($route.path == '/contact'||($route.path.indexOf('recruitItem')!=-1)||scrollTop>500?logoDark:logoLight)})` }"
              >
<!--            <img :src="($route.path == '/contact'||($route.path.indexOf('recruitItem')!=-1)||scrollTop>500?logoDark:logoLight)" alt=""/>-->
          </a>
        </div>
        <div class="middle">
          <ul ref="navList">
            <!-- 移动端折叠图标 -->
            <li style="display: none">
              <a href="#" @click="unfold"
                ><i class="el-icon-s-operation" :style="{ color: foldIcon }"></i
              ></a>
            </li>
            <li>
              <a
                href="#/index"
                :class="{
                  current: $route.path == '/index',
                  // DarkCurrent: DarkColor,
                  slight: DarkColor,
                }"
                >首页

              </a
              >
            </li>

            <li @mouseenter="pshowFn('solution')"  @mouseleave="pshowFn('solution')">
              <a
                  style="position: relative"
                  href="/#/digitalArchive?id=dangan"
                  :class="{ slight: DarkColor, current: $route.path.indexOf('digitalArchive')!=-1  }"
              >解决方案
                <ol style="position: absolute;left: -80%" :class="{ open: solutionShow }">
                  <li v-for="(val,index) in solutionList" :key="index" >
                    <a :href="val.link">{{ val.name }}</a>
                  </li>
                </ol>
              </a
              >
            </li>
            <li @mouseenter="pshowFn"  @mouseleave="pshowFn">
              <a
                href="#/product"
                style="position: relative"
                :class="{ slight: DarkColor, current: $route.matched[0].path == '/product' }"
                >产品中心   <ol style="position: absolute;left: -80%" :class="{ open: pshow }">
                <li v-for="(val,index) in productList" :key="index" >
                  <a :href="val.link">{{ val.name }}</a>
                </li>
              </ol></a
              >




            </li>


            <!-- <li><a href="#" :class="{slight:DarkColor}">案例</a></li> -->
            <!-- <li><a href="#" :class="{slight:DarkColor}">下载</a></li> -->
            <li>
              <a
                href="#/cooperation"
                :class="{ slight: DarkColor, current: $route.path == '/cooperation' }"
                >合作</a
              >
            </li>
            <!-- <li><a href="#" :class="{slight:DarkColor}">档案知识库</a></li> -->
            <!-- <li @mouseenter="MeshowFn" @mouseleave="MeshowFn">
              <a
                href="#/introduce"
                :class="{ slight: DarkColor, current: active[3] }"
                @click="mark(3)"
                >关于我们</a
              >
              <ol :class="{ open: Ashow }">
                <li><a href="#/introduce" @click="mark(3)">企业介绍</a></li>
                <li><a href="#/contact" @click="mark(3)">联系我们</a></li>
              </ol>
            </li> -->
<!--            <li>-->
<!--              <a-->
<!--                href="#/introduce"-->
<!--                :class="{ slight: DarkColor, current: $route.path == '/introduce' }"-->
<!--                >企业介绍</a-->
<!--              >-->
<!--            </li>-->
            <li>
              <a
                href="#/instance"
                :class="{ slight: DarkColor, current: $route.path == '/instance' }"
                >案例</a
              >
            </li>
            <li>
              <a
                href="#/news"
                :class="{ slight: DarkColor, current: $route.path == '/news' }"
                >新闻公告</a
              >
            </li>
            <li>
              <a
                  href="#/consultation"
                  :class="{ slight: DarkColor, current: $route.path == '/consultation' }"
              >行业资讯</a
              >
            </li>

<!--            <li>-->
<!--              <a-->
<!--                href="#/contact"-->
<!--                :class="{ slight: DarkColor, current: $route.path == '/contact' }"-->
<!--                >联系我们</a-->
<!--              >-->
<!--            </li>-->
<!--            <li>-->
<!--              <a-->
<!--                  href="#/recruitList"-->
<!--                  :class="{ slight: DarkColor, current: $route.path == '/recruitList' }"-->

<!--              >加入我们</a-->
<!--              >-->
<!--            </li>-->

                        <li @mouseenter="pshowFn('firm')"  @mouseleave="pshowFn('firm')">
                          <a
                              style="position: relative"
                            href="#/introduce"
                            :class="{ slight: DarkColor, current: $route.path == '/introduce' }"
                            >关于我们
                            <ol style="position: absolute;left: -80%" :class="{ open: firmtShow }">
                              <li v-for="(val,index) in firmtList" :key="index" >
                                <a :href="val.link">{{ val.name }}</a>
                              </li>
                            </ol>
                          </a
                          >
                        </li>

          </ul>
        </div>
        <div class="right">
<!--          <h3 :class="{ slight: DarkColor }">咨询热线</h3>-->
        <a href="#" style="color: red"><div>4008803557</div>
          <div>15735645888</div>

        </a>
          <div class="top-button-box">          <el-button type="primary" style="background-color: #1f5dea;border: #1f5dea"  round @click="showOpen" >免费体验</el-button></div>

        </div>
      </div>
      <div ref="collapsePannel" class="collapsePannel">
        <ul>
          <li>
            <a
              href="#/index"
              :class="{
                current: $route.path == '/index',
                // DarkCurrent: DarkColor,
                slight: DarkColor,
              }"
                @click="unfold();"
              >首页</a
            >
          </li>
          <li v-for="(val,index) in productList" :key="index">
            <a
              :class="{current:'#'+$route.path == val.link}"
              :href="val.link"
              @click="unfold();"
              >{{ val.name }}</a
            >
          </li>
          <li>
            <a
              href="#/cooperation"
              :class="{ slight: DarkColor, current: $route.path == '/cooperation' }"
              @click="unfold();"
              >合作</a
            >
          </li>
          <li>
              <a
                href="#/instance"
                :class="{ slight: DarkColor, current: $route.path == '/instance' }"
                @click="unfold();"
                >案例</a
              >
            </li>
            <li>
              <a
                href="#/news"
                :class="{ slight: DarkColor, current: $route.path == '/news' }"
                @click="unfold();"
                >新闻</a
              >
            </li>
          <li>
            <a
              href="#/introduce"
              :class="{ slight: DarkColor, current: $route.path == '/introduce' }"
              @click="unfold();"
              >企业介绍</a
            >
          </li>

          <li>
            <a
                href="#/contact"
                :class="{ slight: DarkColor, current: $route.path == '/contact' }"
                @click="unfold();"
            >解决方案</a>
          </li>
          <li>
            <a
              href="#/contact"
              :class="{ slight: DarkColor, current: $route.path == '/contact' }"
              @click="unfold();"
              >联系我们</a
            >
          </li>
          <li>
            <a
                href="#/recruitList"
                :class="{ slight: DarkColor, current: $route.path == '/recruitList' }"

            >加入我们</a
            >
          </li>

        </ul>
      </div>
    </div>
    <!-- 侧边导航栏 -->
<!--    <div class="asile-nav">-->
<!--      <ul>-->
<!--        <li v-for="(item,index) in asileList" :key="index">-->
<!--          <a href="#"><img :src="item" alt="" /></a>-->
<!--        </li>-->
<!--      </ul>-->
<!--      <div class="backTop">-->
<!--        <a href="#"-->
<!--          ><img src="../assets/images/contact-icon-top.png" alt=""-->
<!--        /></a>-->
<!--      </div>-->
<!--    </div>-->

<!--   <div class="open" id="showcp"  @mouseleave="closepshowFn()">-->
<!--     <div class="modtext">-->

<!--       <div class="boxes">-->

<!--         <h4 class="tit">产品中心</h4>-->

<!--         <p>山西禾祺科技有限公司（以下简称“公司”或“禾祺科技”）创始于2013年年4月。经过多年发展，已经成为档案行业专业解决方案服务商，禾祺科技专注为各地的政府机关、大中型企事业单位提供集档案管理咨询、档案整理、档案数字化加工；智慧感知档案库房建设；数字档案馆（室）建设；档案监理；数据保护、档案托管、档案管理系统研发、档案信息化等为一体的专业化档案服务公司</p>-->



<!--       </div>-->

<!--     </div>-->
<!--      <div class="modsubList">-->
<!--        <ul class="ultop">-->

<!--          <li v-for="(val,index) in productList" :key="val.link" :class="{liactive:index==hoverindex}" @mouseenter="hoverindexfun(index)">-->
<!--            <a :href="val.link">{{ val.name }}</a>-->
<!--            <img :src="imgsrcsrc" />-->
<!--          </li>-->

<!--        </ul>-->
<!--        <img :src="imgachive" class="imgachive">-->

<!--      </div>-->

<!--   </div>-->
    <el-dialog title="请填写联系方式,我们为您提供免费试用的账号" :visible.sync="open"  @close="cancel"
               width="600px"  center   append-to-body >

      <div><span >姓名</span><el-input v-model="userName" placeholder="请输入内容" ></el-input></div>
      <div><span>所属单位</span><el-input v-model="affiliation"  placeholder="请输入内容"></el-input></div>
      <div><span>电话号码</span><el-input  v-model="phone" placeholder="电话号码"></el-input></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="comimtForm">确 定</el-button>

        <el-button @click="cancel">取 消</el-button>
      </div>

    </el-dialog>
  </div>

</template>

<script>
import {gsap} from "gsap";
import {insertUserInfo} from "@/api/kms/userInfo";
import {Message} from "element-ui";
export default {
  data() {

    return {
      //判断是否是要触发滚动是变透明
      isTransparent:false,
      open:false,
      phone:'',
      //用户姓名
      userName:'',
      //所属单位
      affiliation:'',
      imgachive:require("../assets/images/home/files-1633406_1280.jpg"),
      //鼠标移入的索引
      hoverindex:0,
      //右箭头
      imgsrcsrc: require("../assets/images/home/arrow-right.png"),
      logoLight:require('../assets/images/logo3.png'),
      logoDark:require('../assets/images/logo3.png'),
      pshow: false, //产品中心列表展开
      Ashow: false, //关于我列表展开,
      firmtShow:false, //关于我们,
      solutionShow:false, //解决方案,
      navBgcolor: "transparent",
      DarkColor: false,
      foldIcon: "rgba(255, 255, 255, 0.8)",
      scrollTop:null,
      //后期保留，重构用数组
      navList:[
        {
          url:'#/index',

        }
      ],
      productList: [
        { name: "档案管理平台", link: "#/product/manage" },
        { name: "档案四性检测", link: "#/product/use" },
        { name: "声像档案管理", link: "#/product/identification" },
        { name: "档案智能著录", link: "#/product/intelligence" },
      ],
      firmtList: [

        { name: "企业介绍", link: "#/introduce" },
        { name: "联系我们", link: "#/contact" },
        { name: "加入我们", link: "#/recruitList "},
        { name: "企业资质", link: "#/aptitudeShow "}
      ],
      solutionList: [

        { name: "数字档案管理", link: "#/digitalArchive?id=dangan" },
        { name: "集团企业档案管理", link: "#/digitalArchive?id=jituan" },
        { name: "高校档案管理", link: "#/digitalArchive?id=gaoxiao"},
        { name: "档案馆三网建设", link: "#/digitalArchive?id=danganguan"}

      ],

      asileList: [
        require("../assets/images/contact-tel.png"),
        require("../assets/images/contact-wx.png"),
        require("../assets/images/contact-qq.png"),
        require("../assets/images/contact-email.png"),
      ],
      navShow: false,
    };
  },
  methods: {
    showOpen(){
      this.open=true
    },
    cancel(){
      this.open = false;
    },
    comimtForm(){

      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if(this.phone===''){

        this.$message({
          type: 'warning',
          message: '手机号不能为空'
        });
        return;
      }
      if(!reg_tel.test(this.phone)){

        this.$message({
          type: 'warning',
          message: '请输入正确格式的电话号码'
        });

        return;
      }
      if(this.userName===''){

        this.$message({
          type: 'warning',
          message: '姓名不能为空'
        });
        return
      }
      if(this.affiliation===''){
        this.$message({
          type: 'warning',
          message: '单位不能为空'
        });
        return;
      }
      let parm={phone: this.phone,userName:this.userName,affillation:this.affiliation};
      insertUserInfo(parm).then(response => {
        console.log('response',response)
        if(response.data.code=='200'){
          Message({
            message: '申请成功，我们的客户将在稍后与您进行联系',
            type: 'success'
          })
          this.cancel()
        }else {
          Message({
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    hoverindexfun(index){
      document.querySelectorAll(".ultop>li").forEach((item)=>{
        item.className=""
      })

      document.querySelectorAll(".ultop>li")[index].className="liactive"

    },
    closepshowFn() {

      gsap.to(".open",{css:{
          top:'-40vh'

        },duration:0.8,onComplete:()=>{
          window.document.getElementById("showcp").style.display='none'
        }})

    },
    pshowFn(type) {
      // window.document.getElementById("showcp").className="open"

      if(type==='solution'){
        this.solutionShow = !this.solutionShow;
        return
      }
      if(type==='firm'){
        this.firmtShow = !this.firmtShow;
        return
      }


      this.pshow = !this.pshow;
      // gsap.to(".open",{duration:0.8,css:{
      //   top:'0vh',
      //   },onStart:()=>{
      //     window.document.getElementById("showcp").style.display='block'
      //   }})

    },
    MeshowFn() {
      this.Ashow = !this.Ashow;
    },
    handleScroll() {

        this.navBgcolor = "#fff"; // scrollTop + 多少根据自己的需求设置
        this.DarkColor = true;
        this.foldIcon = "#666";
      // this.scrollTop = window.pageYOffset; //获取滚动高度
      // if (this.scrollTop > 500|| this.isTransparent) {
      //   this.navBgcolor = "#fff"; // scrollTop + 多少根据自己的需求设置
      //   this.DarkColor = true;
      //   this.foldIcon = "#666";
      // } else if (this.scrollTop === 0 && this.$route.path !== "/contact"&& this.$route.path !== "/instance"&& this.$route.path !== "/infolistItem"&& this.$route.path !== "/digitalArchive") {


      // }


    },
    unfold() {
      if (!this.navShow) {
        this.$refs.collapsePannel.style.maxHeight = "400px";
      } else {
        this.$refs.collapsePannel.style.maxHeight = "0px";
      }
      this.navShow = !this.navShow;
    },
    handleChange(val) {
      console.log(val);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {},
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route: {
      handler: function (val) {
        // if (val.fullPath === "/contact"||val.fullPath.indexOf("/recruitItem")!=-1  ||val.fullPath.indexOf("/instance")!=-1 ||val.fullPath === "/infolistItem"||val.fullPath.indexOf("/digitalArchive")!=-1 ) {
        //   // console.log('方法进入测试，watch')
        //   this.navBgcolor = "#fff";
        //   this.DarkColor = true;
        //   this.isTransparent=true
        // } else {
        //   this.navBgcolor = "transparent"; // 设置回到顶部时，背景颜色为透明
        //   this.DarkColor = false;
        //
        //   this.isTransparent=false
        // }

        this.navBgcolor = "#fff";
        this.DarkColor = true;
        this.isTransparent=true
      },
      // 深度观察监听
      deep: true,
      immediate:true
    },
  },
};
</script>
<style>
/*@import "../../nnode_modules/vue2-animate/dist/vue2-animate.css";*/
</style>

<style scoped>
/* 移入移除元素开关 */
.open {
  /*width: 664px;*/
  display: block !important;
  //position: relative;
  //left: 10px;


}

.container {

  /* min-width: 1200px; */
  margin: 0 auto;
}

.nav {
  width: 100%;
  height: 68px;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: all 1.5s;
}
.closeshow{
  display: none;
}
.slight {
  color: #999 !important;
}

.nav .container {
  display: flex;
}

.nav .container .left {
  padding-top: 10px;
  flex: 2;
  display: flex;
  justify-content: center;
}

.nav .container .left img {
  width: 180px;
  height: 48px;
  display: block;
}

.nav .container .middle {
  flex: 5;
}

.nav .container .middle ul {
  height: 68px;
  line-height: 68px;
  display: flex;
  justify-content: space-evenly;
}

.nav .container .middle ul li a {
  display: inline-block;
  color: rgba(255, 255, 255, 0.6);
}
.open .modtext {
  width: 50%;
  display: inline-block;
  height: 40vh;
  border-right: 1px solid #e6e6e6;
  background: #fafafa;

}
.open .modsubList{
  display: inline-block;
  color: #231815;
  font-size: 16px;
  font-family: Arial,"Microsoft YaHei";
  width: 60%;
  height: 40vh;
  position: absolute;


}
.open .modsubList .ultop{
width: 50%;
  height: 60%;
 position: relative;
  top: 20%;
  left: 30%;

}

.open .modsubList li{
  text-align: center;
  width: 40%;
  height: 20%;
 padding-top: 1.5%;

}
.open .modsubList li img{
  /*width: 20%;*/
  /*display: none;*/
  display: inline-block;
  margin-left: 20px;

  width: 20px;
  height: 20px;
}


.liactive{
  color: white;
  background-color: #2e64af;
}
.liactive >a{
  color: #FFFFFF;
}


.open .modtext .boxes .tit {
  font-size: 24px;
  line-height: 2;
  font-weight: 400;
  margin-bottom: 5px;
  margin-left: 20%;
  margin-top: 10%;
}
.open .modtext .boxes p {
  font-size: 14px;
  width: 426px;
  text-align: justify;
  line-height: 2;
  color: grey;
  margin-left: 20%;
}

/* 多级菜单右边的向下折叠小箭头 */
/* .nav .container .middle ul li:nth-child(3) a::after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  width: 10px;
  height: 6px;
  line-height: 68px;
  vertical-align: middle;
  background-image: url("../assets/images/nav-arrow-down.png");
} */

.nav .container .middle ul li:hover {
  border-bottom: 1px solid white;
}

.nav .container .middle .current {
  color: #0f9dfc !important;
  border-bottom: 2px solid #0f9dfc;
}

.nav .container .middle .DarkCurrent {
  color: #0f9dfc !important;
  border-bottom: 1px solid white;
}

.nav .container .middle ol {
  display: none;
  width: 192px;
  background-color: #fff;
  text-align: center;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 0px 13px rgba(237, 240, 243, 1);
}

.nav .container .middle ol li {
  width: 192px;
  height: 40px;
  line-height: 40px;
}

.nav .container .middle ol li a {
  width: 192px;
  color: #363637;
  height: 40px;
  line-height: 40px;
}

.nav .container .middle ol li a::after {
  content: "";
  background-image: none !important;
}

.nav .container .middle ol li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #0f9dfc !important;
}

.nav .container .right {
  flex: 3;
  text-align: center;
  //line-height: 68px;
  display: flex;
  align-items: center; /* 垂直居中 */
  gap: 10px; /* 控制元素间距，可以根据需要调整 */

}

.nav .container .right h3 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.nav .container .right h3::before {
  content: "";
  display: inline-block;
  width: 38px;
  height: 38px;
  background-image: url("../assets/images/contact-tel.png");
  vertical-align: middle;
}

.nav .container .right a {
  font-size: 22px;
  font-weight: bold;
  //color: red;
  display: inline-block;
  padding: 0px 10px;
}

.nav .container .right a:hover {
  //background-color: rgba(255, 255, 255, 0.6);
  //border-radius: 25px;
}

.asile-nav {
  position: fixed;
  z-index: 999;
  top: 240px;
  right: 30px;
}

.asile-nav ul {
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.asile-nav ul li {
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
}

.asile-nav ul li img {
  padding: 5px 6px 10px;
  border-bottom: 1px solid #fff;
}

.asile-nav ul li:last-child img {
  border-bottom: 0;
}

.asile-nav .backTop {
  width: 70px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  vertical-align: middle;
}

.asile-nav .backTop:hover {
  background-color: #0f9dfc;
}

.collapsePannel {
  background: rgba(255, 255, 255, 1);
  max-height: 0;
  transition: all 0.5s;
  overflow: hidden;
}pen
.collapsePannel .current{
  color:red;
}
.collapsePannel ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.collapsePannel ul li {
  width: 100%;
}
.collapsePannel ul li a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px 0;
  color: #666;
}
.imgachive{
  display: inline-block;
  width: 200px;
  height: 150px;
  position: absolute;
  top: 20%;
  right: 15%;

}
.collapsePannel ul li a:hover {
  background: rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .collapsePannel {
    height: 0;
  }
}

@media (max-width: 992px) {
  .asile-nav {
    display: none;
  }

  .nav .container .right {
    display: none;
  }
}

@media (max-width: 768px) {
  .nav .container .middle ul li:nth-child(n) {
    display: none;
  }
  .nav .container .middle ul li:nth-child(1) {
    display: block !important;
    font-size: 30px;
  }
  .nav .container .middle ul li:nth-child(1) a {
    color: rgba(255, 255, 255, 0.8);
  }
  .nav .container .middle ul li:nth-child(1):hover {
    border-bottom: none;
  }
  /* OrangeCat */
  .nav .container .middle ul {
    justify-content: end;
    padding-right: 30px;
  }
}
.logo{
  display: inline-block;
  width: 180px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>