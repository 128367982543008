<template>
  <div>

    <div class="main-box">

      <h2 data-aos="fade-up" style="margin-top: 2%">企业资质</h2>

<!--      <swiper ref="mySwiper" :options="swiperOptions" class="swiper-Box"  v-if="dataList.length">-->

<!--        <swiper-slide  v-for="(item,index) in dataList" :key="index"   >-->
<!--          &lt;!&ndash;      <a>{{itme.sysName}}</a>&ndash;&gt;-->

<!--            <div class="swiper-slide-box">-->
<!--              <img :src="item.src"    />-->
<!--              <p>{{item.name}}</p>-->
<!--            </div>-->


<!--          &lt;!&ndash;              <el-video :auto-play="true" :src="item.filePath" class="video-player"></el-video>&ndash;&gt;-->


<!--        </swiper-slide>-->
<!--        <div class="swiper-button-prev" v-show="showSwiperButton" slot="button-prev"></div>-->
<!--        <div class="swiper-button-next" v-show="showSwiperButton"  slot="button-next"></div>-->
<!--      </swiper>-->
      <ul class="aptitude-list" data-aos="fade-up" >
        <li v-for="(item,index) in minDataList"  :key="index" class="li-box">
<!--                      <div class="swiper-slide-box">-->
                        <img :src="item.src"     />
                        <p class="p-text" >{{item.name}}</p>
<!--                      </div>-->

        </li>

        <el-pagination
            background
            v-show="dataList.length>0"
            :total="dataList.length"
            :current-page="pageNum"
            :page-size="pageSize"
            @current-change="updateList"
        />

      </ul>

    </div>



  </div>
</template>

<script>


export default {
  name: "aptitude",
  data(){
    return {
      showSwiperButton:false,
      pageNum: 1,
      pageSize: 6,
      swiperOptions: {
        // autoplay:true,
        // //设置环路
        // loop: true,
        // 如果需要分页器

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 如果需要滚动条
        effect: 'fraction',
        slidesPerView : 3,
        initialSlide: 1,
        clickable: true,
        coverflowEffect: {
          // rotate: 25,
          // stretch: 0,
          // // depth: 120,
          // modifier: 1,
          // slideShadows : false
          // rotate: -25,
          // stretch: 0,
          // // depth: 120,
          // modifier: 1,
          // slideShadows : false

        },grid: {
          fill: 'column',
          rows: 4,
        },


      },

      dataList:[],
      imgList:[
        require('@/assets/images/zizhi/zzrz17.jpg'),
        require('@/assets/images/zizhi/zzrz18.jpg'),
        require('@/assets/images/zizhi/zzrz19.jpg'),
        require('@/assets/images/zizhi/zzrz04.jpg'),
        require('@/assets/images/zizhi/zzrz05.jpg'),
        require('@/assets/images/zizhi/zzrz06.jpg'),
        require('@/assets/images/zizhi/zzrz01.jpg'),
        require('@/assets/images/zizhi/zzrz02.jpg'),
        require('@/assets/images/zizhi/zzrz03.jpg'),
        require('@/assets/images/zizhi/zzrz07.jpg'),
        require('@/assets/images/zizhi/zzrz08.jpg'),
        require('@/assets/images/zizhi/zzrz09.jpg'),
        require('@/assets/images/zizhi/zzrz10.jpg'),
        require('@/assets/images/zizhi/zzrz11.jpg'),
        require('@/assets/images/zizhi/zzrz12.jpg'),
        require('@/assets/images/zizhi/zzrz13.jpg'),
        require('@/assets/images/zizhi/zzrz14.jpg'),


      ],
      nameList:['质量管理体系认证证书',
        '职业健康安全管理体系认证证书','环境管理体系认证证书','计算机软著'
        ,'计算机软著','计算机软著','计算机软著'
        ,'计算机软著','计算机软著','质量诚信服务单位','企业资信息等级证书'
                ,'诚信供应商等级证书','诚信经营示范单位等级认证证书','重合同守信用等级认证证书',
                 '招投标企业信用等级证书',
                '重服务守信用企业认证证书',
                '重质量守信证书'],
      minDataList:[]
    }

  },
  created() {
            this.initDataList()
  },
  methods:{

    openPicture(url){
      window.open(url)
    },
    initDataList(){

      this.imgList.forEach((item,index)=>{
            this.dataList.push({'src':item,'name':this.nameList[index]});
      })
      this.updateList()
    },
    updateList(val){
      this.pageNum=val?val:1
      this.minDataList=[]
      // eslint-disable-next-line no-empty
      let start= this.pageSize * (this.pageNum-1)
      let end= this.pageSize * this.pageNum
      if(end>this.dataList.length){
        end=this.dataList.length
        //新增空白卡片占位置
      }

      for( let i =start ; i <end ; i++ ) {
                    this.minDataList.push(this.dataList[i])
      }
      console.log("minDataList",this.minDataList)
    }


  }
}
</script>

<style scoped>
.swiper-Box{
  margin-top:4%;
//margin-left:20% ;
  width: 72%;
  height: 80%;

}
  .main-box{
    width: 100%;
    height: 100%;
    padding-bottom: 5%;

    margin: 0 auto;
    text-align: center;

  }
.main-box h2 {
  font-size: 38px;
  color: #0f9dfc;
  text-align: center;
  font-weight: 400;
  margin-bottom: 80px;
}
  .aptitude-list{
    margin: 0 auto;
    display: flex;
    width: 60%;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-around;
    //background-color: #e1edf9;
    padding-top: 2%;


  }
  .li-box{
    width: 33%;
    text-align: center;
    //height: 40%;
    margin-bottom: 2%;

  }
  .p-text{
    //font-size: 18px;
    //color: #231815;
    //font-family: Arial,"Microsoft YaHei";

  }

.swiper-slide-box{
  height: 100%;
  width: 100%;
}
.li-box>img{
  width: 80%;
  height: 80%;

  background-color: #e1edf9;

}









</style>